<template>
  <div>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Ad Soyad"
                label-for="nameSurname"
            >
              <b-form-input
                  id="nameSurname"
                  placeholder="Ad Soyad"
                  v-model="nameSurname"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Telefon"
                label-for="phone"
            >
              <b-form-input
                  id="phone"
                  placeholder="Telefon"
                  v-model="phone"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Adres"
                label-for="address"
            >
              <b-form-input
                  id="address"
                  placeholder="Adres"
                  v-model="address"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.once="customerSave"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BBadge,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BBadge,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    customerInfo: Object,
  },
  data() {
    return {
      customerId: 0,
      nameSurname: '',
      address: '',
      phone: '',
    }
  },
  computed: {},
  mounted() {

  },
  created() {
    this.customerId = this.customerInfo.id;
    this.nameSurname = this.customerInfo.nameSurname;
    this.address = this.customerInfo.address;
    this.phone = this.customerInfo.phone;
  },
  methods: {
    customerSave() {
      axios.put('/customer/update', {
        customerId: this.customerId,
        nameSurname: this.nameSurname,
        address: this.address,
        phone: this.phone,
      }).then(response => {
        this.$toastApiResponse(response.data)
        this.$emit('editModal', true)
      })
          .catch(err => {
            this.$toastBaha(err.message, 'danger')
          })
    }
  },
}
</script>

<style>

</style>
